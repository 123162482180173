import React from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import {
  height,
  HeightProps,
  MarginProps,
  space,
  SpaceProps
} from 'styled-system'
import {
  DEFAULT_FALLBACK_IMAGE,
  DEFAULT_FALLBACK_IMAGE_NAME
} from 'enums/image'
import useTranslation from 'next-translate/useTranslation'

export interface CoverImageProps extends HeightProps, SpaceProps, MarginProps {
  /**
   * Takes a string. corresponds to the background.
   */
  src?: string
  /**
   * Takes ReactFragment and string. corresponds to the content displayed on the top of the image.
   */
  top?: React.ReactNode
  /**
   * Takes ReactFragment and string. corresponds to the content displayed on the bottom of the image.
   */
  bottom?: React.ReactNode
  /**
   * Takes a boolean. corresponds to the overlay.
   */
  overlay?: boolean
  /**
   * If `true`, the button will be round.
   */
  round?: boolean
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string
}

/**
 * The CoverImage component is used for rendering images.
 *
 * It uses the styled-system to handle all the props related to space.
 * Pass the rest of the props "...other" to the element that should be styled with styled-system.
 * */

const CoverImage = ({
  height = 165,
  src = DEFAULT_FALLBACK_IMAGE,
  top,
  bottom,
  overlay,
  round = true,
  className,
  ...other
}: CoverImageProps) => {
  const { t } = useTranslation('common')

  // check if doesn't have top and bottom elements
  const imageOnly = top === undefined && bottom === undefined

  /**
   * Overlay logic - retrieve a boolean value:
   * - if 'overlay' is defined: use it as value
   * - if 'overlay' is undefined: checks if the attributes 'top' or 'bottom' is defined.
   */
  const renderOverlay =
    overlay !== undefined ? overlay : top !== undefined || bottom !== undefined

  return (
    <StyledCoverImage
      src={src}
      top={top}
      round={round}
      className={className}
      role={imageOnly ? 'img' : undefined} // for assistive technology
      aria-label={t('cover-image')} // for assistive technology
      height={height}
      {...other}
    >
      {renderOverlay && <StyledOverlay data-testid="overlay" />}
      {top && <StyledTop>{top}</StyledTop>}
      {bottom && <StyledBottom>{bottom}</StyledBottom>}
    </StyledCoverImage>
  )
}

export default styled(CoverImage)()

const StyledCoverImage = styled.div<CoverImageProps>(
  ({ theme, round, src }) => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: '100%',
    marginBottom: theme.space[4],
    color: theme.colors.white,
    borderRadius: round ? theme.radii[3] : 0,
    /**
     * The fallback image uses a combination of two images, the first image is a
     * pattern of icons that repeat themselves if the content is wilder than 330px
     * and the second image is a gradient placed behind the icons. */
    background: src?.includes(DEFAULT_FALLBACK_IMAGE_NAME)
      ? `url(${src}) repeat center / 330px auto, linear-gradient(20deg, #F4873D -120%, #F4873D -120%, #EC165A -12%, ${theme.colors.primary} 95%, #22A9E1 200%)`
      : `url(${src}) no-repeat center / cover`
  }),
  height,
  space
)

const StyledOverlay = styled.div(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: `linear-gradient(124.62deg, ${rgba(
    theme.colors.background.primary,
    0.4
  )} 10%, transparent 50%, ${rgba(theme.colors.background.primary, 0.4)} 85%)`
}))

const StyledTop = styled.div(({ theme }) => ({
  position: 'relative',
  zIndex: 0,
  marginTop: theme.space[2],
  marginRight: 'auto',
  marginLeft: theme.space[2]
}))

const StyledBottom = styled.div(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  right: theme.space[2],
  bottom: theme.space[2],
  display: 'flex'
}))
